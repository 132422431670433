import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import ProgressBar from '~/components/ProgressBar.vue';
export default defineComponent({
  components: {
    ProgressBar: ProgressBar
  },
  props: {
    isTrial: {
      type: Boolean,
      default: false
    },
    signatureClasses: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    extraSignatures: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    signaturePrices: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  computed: {
    classKey: function classKey() {
      return {
        ses: this.$t('global.ses'),
        aes: this.$t('global.aes'),
        qes: this.$t('global.qes'),
        part11: this.$t('global.part11')
      };
    },
    disabledQualities: function disabledQualities() {
      return this.signaturePrices.filter(function (item) {
        return item.pricePer === -1;
      }).map(function (item) {
        return item.class;
      });
    }
  },
  methods: {
    /**
     * Return a limited unsigned integer (0, 100) representing
     * the percentage of signatures used within a certain class
     * unlimited for a class will always return 100
     */
    classPercent: function classPercent(signatureMeterItem) {
      return signatureMeterItem.total === -1 ? 100 : Math.min(100, Math.max(0, Math.round(signatureMeterItem.used / signatureMeterItem.total * 100)));
    },
    /**
     * Return the color for the progress bar suitable for the
     * value of the item.
     * unlimited has no measurement, so its grey
     */
    progressColor: function progressColor(signatureMeterItem) {
      var percentageUsed = this.classPercent(signatureMeterItem);
      if (this.isTrial) {
        return percentageUsed < 90 ? 'green' : 'red';
      }
      if (signatureMeterItem.total === -1) {
        return 'grey';
      }
      if (signatureMeterItem.class === 'PART11') {
        return 'purple';
      }
      return percentageUsed >= 90 ? 'red' : 'blue';
    },
    /**
     * Build human-readable display version of provided
     * signature class key.
     */
    classCopy: function classCopy(key) {
      var _this = this;
      return key.split('_').map(function (c) {
        return _this.classKey[c.toLowerCase()];
      }).join(', ');
    }
  }
});